import React, { useState } from "react";
import Resume from '../files/AidanPolm_SWE_Resume.pdf';

function Home({ theme, setTheme, themes }) {
  const [selectedTheme, setSelectedTheme] = useState("Modern");

  const handleThemeChange = (event) => {
    const selected = event.target.value;
    setSelectedTheme(selected);

    switch (selected) {
      case "Option 1":
        setTheme(themes.option1);
        break;
      case "Option 2":
        setTheme(themes.option2);
        break;
      case "Option 3":
        setTheme(themes.option3);
        break;
      default:
        setTheme(themes.modern);
        break;
    }
  };

  return (
    <div className="wrapper p-8 flex flex-row justify-between">
      <div className="w-1/4 mr-20">
        <div className={`shadow-md ${theme.shadowColor} ${theme.cardLightBgColor} rounded-2xl flex flex-row justify-between items-center p-4 px-8`}>
          <div>
            <p className="font-bold text-2xl mb-2">Theme</p>
            <p className="font-extralight text-sm">{selectedTheme}</p>
          </div>
          <div>
            <select
              onChange={handleThemeChange}
              className="bg-transparent rounded-lg border border-slate-500 px-2 py-1 cursor-pointer transition-all duration-300"
            >
              <option>Modern</option>
              <option>Option 1</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </select>
          </div>
        </div>

        <div className={`${theme.cardLightBgColor} mt-6 p-6 rounded-2xl shadow-md ${theme.shadowColor}`}>
          <div className="py-6 text-center border-b border-b-gray-400">
            <h1 className="text-5xl font-bold mb-4">Aidan Polm</h1>
            <p className="text-lg">Full Stack Developer</p>
            <ul className="flex flex-row flex-wrap justify-evenly text-lg mt-4">
              <li className="rounded-full w-fit m-1">Java</li>
              <li className="rounded-full w-fit m-1">React</li>
              <li className="rounded-full w-fit m-1">Swift</li>
              <li className="rounded-full w-fit m-1">Python</li>
            </ul>
          </div>

          <div className="py-6 border-b border-b-gray-400">
            <div className="flex flex-col space-y-4">
              <a href="https://www.github.com/aidan-polm/" target="_blank" rel='noreferrer' className={`hover:text-${theme.accentSecondary} transition-all ease-in-out duration-300 hover:scale-105 flex items-center`}>
                <i className="fab fa-github text-2xl mr-2"></i>
                aidan-polm
              </a>
              <a href="https://www.linkedin.com/in/aidan-polm/" target="_blank" rel='noreferrer'  className={`hover:text-${theme.accentPrimary} transition-all ease-in-out duration-300 hover:scale-105 flex items-center`}>
                <i className="fab fa-linkedin-in text-2xl mr-2"></i>
                aidan-polm
              </a>
              <a href="mailto:aidantpolm@gmail.com" className={`hover:text-${theme.accentSecondary} transition-all ease-in-out duration-300 hover:scale-105 flex items-center`}>
                <i className="far fa-envelope text-2xl mr-2"></i>
                aidantpolm@gmail.com
              </a>
            </div>
          </div>

          <div className="py-6 border-b border-b-gray-400">
            <div className="mb-6">
              <p className="text-lg mb-1 font-bold">B.S. Software Engineering</p>
              <p className="text-sm">Western Governors University</p>
              <p className="text-sm font-light">November 2024</p>
            </div>
            <div className="mb-6">
              <p className="text-lg mb-1 font-bold">B.S. Music Business</p>
              <p className="text-sm">Minor Entrepreneurship</p>
              <p className="text-sm">Middle Tennessee State University</p>
              <p className="text-sm font-light">May 2022</p>
            </div>
          </div>
        </div>

        <div className="relative flex flex-row w-full py-6">
          <a href={Resume} target="_blank" rel='noreferrer' className={`group relative shadow-md ${theme.cardDarkBgColor} ${theme.shadowColor} rounded-2xl cursor-pointer w-full h-fit hover:scale-105 transition-all duration-300`}>
            <div className={`group ${theme.cardLightBgColor} w-3/4 z-9 group-hover:bg-gray-300 rounded-s-2xl p-4 px-8 transition duration-300`}>
              <p className="font-bold text-2xl mb-2">Resume<i className="fas fa-arrow-right text-lg transition-all ease-in-out duration-200 group-hover:ml-2 group-hover:opacity-100 opacity-0"></i></p>
            </div>
            <div className=" opacity-0 md:opacity-100 md:w-1/4">
              <div className={`absolute ${theme.accentPrimary} bottom-4 right-4 w-4 h-4 rounded-3xl transition-all duration-300 ease-in-out group-hover:w-1/4 group-hover:bottom-0 group-hover:right-0 group-hover:h-full group-hover:rounded-s-none group-hover:rounded-e-2xl`}></div>
            </div>
          </a>
        </div>


      </div>

      <div className="w-3/4">
        <div>
          <p className="w-full text-4xl bg-transparent font-bold">Projects & Github</p>
        </div>

        <div className="flex flex-col lg:flex-row lg:flex-wrap mt-6 justify-around">
          <a href="https://sportscoreiq.com" target="_blank" rel='noreferrer' className={`group relative shadow-md ${theme.cardDarkBgColor} ${theme.shadowColor} rounded-2xl cursor-pointer lg:w-2/5 h-fit m-4 hover:scale-105 transition-all duration-300`}>
            <div className={`group ${theme.cardLightBgColor} w-3/4 z-9 group-hover:bg-gray-300 rounded-s-2xl p-4 px-8 transition duration-300`}>
              <p className="font-bold text-2xl mb-2">Sport Score IQ<i className="fas fa-arrow-right text-lg transition-all ease-in-out duration-200 group-hover:ml-2 group-hover:opacity-100 opacity-0"></i></p>
              <p className="font-extralight text-sm mb-2">Website that uses machine learning and predictive models to predict the results of a sports event.</p>
              <p className="text-right font-semibold">Python, React</p>
            </div>
            <div className="opacity-0 md:opacity-100 md:w-1/4">
              <div className={`absolute ${theme.accentSecondary} bottom-4 right-4 w-4 h-4 rounded-3xl transition-all duration-300 ease-in-out group-hover:w-1/4 group-hover:bottom-0 group-hover:right-0 group-hover:h-full group-hover:rounded-s-none group-hover:rounded-e-2xl`}></div>
            </div>
          </a>

          <a href="https://innabook.com" target="_blank" rel='noreferrer' className={`group relative shadow-md ${theme.cardDarkBgColor} ${theme.shadowColor} rounded-2xl cursor-pointer lg:w-2/5 h-fit m-4 hover:scale-105 transition-all duration-300`}>
            <div className={`group ${theme.cardLightBgColor} w-3/4 z-9 group-hover:bg-gray-300 rounded-s-2xl p-4 px-8 transition duration-300`}>
              <p className="font-bold text-2xl mb-2">Innabook<i className="fas fa-arrow-right text-lg transition-all ease-in-out duration-200 group-hover:ml-2 group-hover:opacity-100 opacity-0"></i></p>
              <p className="font-extralight text-sm mb-2">Website that allows educators to find books and lesson plans for a specific topic or curriculum.</p>
              <p className="text-right font-semibold">Python, Java, React</p>
            </div>
            <div className="opacity-0 md:opacity-100 md:w-1/4">
              <div className={`absolute ${theme.accentPrimary} bottom-4 right-4 w-4 h-4 rounded-3xl transition-all duration-300 ease-in-out group-hover:w-1/4 group-hover:bottom-0 group-hover:right-0 group-hover:h-full group-hover:rounded-s-none group-hover:rounded-e-2xl`}></div>
            </div>
          </a>

          <a href="https://github.com/aidan-polm/python_projects" target="_blank" rel='noreferrer' className={`group relative shadow-md ${theme.cardDarkBgColor} ${theme.shadowColor} rounded-2xl cursor-pointer lg:w-2/5 h-fit m-4 hover:scale-105 transition-all duration-300`}>
            <div className={`group ${theme.cardLightBgColor} w-3/4 z-9 group-hover:bg-gray-300 rounded-s-2xl p-4 px-8 transition duration-300`}>
              <p className="font-bold text-2xl mb-2">Python Projects<i className="fas fa-arrow-right text-lg transition-all ease-in-out duration-200 group-hover:ml-2 group-hover:opacity-100 opacity-0"></i></p>
              <p className="font-extralight text-sm mb-2">Three mini projects: a password generator, a blackjack game, and a rock/paper/scissors game.</p>
              <p className="text-right font-semibold">Python</p>
            </div>
            <div className=" opacity-0 md:opacity-100 md:w-1/4">
              <div className={`absolute ${theme.accentPrimary} bottom-4 right-4 w-4 h-4 rounded-3xl transition-all duration-300 ease-in-out group-hover:w-1/4 group-hover:bottom-0 group-hover:right-0 group-hover:h-full group-hover:rounded-s-none group-hover:rounded-e-2xl`}></div>
            </div>
          </a>

          <a href="https://github.com/aidan-polm/java_store" target="_blank" rel='noreferrer' className={`group relative shadow-md ${theme.cardDarkBgColor} ${theme.shadowColor} rounded-2xl cursor-pointer lg:w-2/5 h-fit m-4 hover:scale-105 transition-all duration-300`}>
            <div className={`group ${theme.cardLightBgColor} w-3/4 z-9 group-hover:bg-gray-300 rounded-s-2xl p-4 px-8 transition duration-300`}>
              <p className="font-bold text-2xl mb-2">Java Store<i className="fas fa-arrow-right text-lg transition-all ease-in-out duration-200 group-hover:ml-2 group-hover:opacity-100 opacity-0"></i></p>
              <p className="font-extralight text-sm mb-2">Website that allows users to add parts and products, "buy" products, and adjust inventory.</p>
              <p className="text-right font-semibold">Java, Spring</p>
            </div>
            <div className=" opacity-0 md:opacity-100 md:w-1/4">
              <div className={`absolute ${theme.accentSecondary} bottom-4 right-4 w-4 h-4 rounded-3xl transition-all duration-300 ease-in-out group-hover:w-1/4 group-hover:bottom-0 group-hover:right-0 group-hover:h-full group-hover:rounded-s-none group-hover:rounded-e-2xl`}></div>
            </div>
          </a>

          <a href="https://github.com/aidan-polm/world_map" target="_blank" rel='noreferrer' className={`group relative shadow-md ${theme.cardDarkBgColor} ${theme.shadowColor} rounded-2xl cursor-pointer lg:w-2/5 h-fit m-4 hover:scale-105 transition-all duration-300`}>
            <div className={`group ${theme.cardLightBgColor} w-3/4 z-9 group-hover:bg-gray-300 rounded-s-2xl p-4 px-8 transition duration-300`}>
              <p className="font-bold text-2xl mb-2">World Map<i className="fas fa-arrow-right text-lg transition-all ease-in-out duration-200 group-hover:ml-2 group-hover:opacity-100 opacity-0"></i></p>
              <p className="font-extralight text-sm mb-2">Website that maps a svg file and allows users to select a country to pull and display data via an API.</p>
              <p className="text-right font-semibold">Angular</p>
            </div>
            <div className=" opacity-0 md:opacity-100 md:w-1/4">
              <div className={`absolute ${theme.accentPrimary} bottom-4 right-4 w-4 h-4 rounded-3xl transition-all duration-300 ease-in-out group-hover:w-1/4 group-hover:bottom-0 group-hover:right-0 group-hover:h-full group-hover:rounded-s-none group-hover:rounded-e-2xl`}></div>
            </div>
          </a>

        </div>
      </div>
    </div>
  );
}
export default Home;
