import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";

// Define your theme objects here with additional styles for card and accents
const themes = {
  modern: {
    backgroundColor: 'bg-gray-200',
    textColor: 'text-gray-700',
    font: 'font-sans',
    cardLightBgColor: 'bg-white',
    cardDarkBgColor: 'bg-gray-300',
    shadowColor: 'shadow-gray-400',
    accentPrimary: 'bg-orange-500',
    accentSecondary: 'bg-cyan-600',
  },
  option1: {
    backgroundColor: 'bg-blue-200',
    textColor: 'text-blue-700',
    font: 'font-serif',
    cardLightBgColor: 'bg-blue-100',
    cardDarkBgColor: 'bg-blue-300',
    shadowColor: 'shadow-blue-400',
    accentPrimary: 'bg-purple-500',
    accentSecondary: 'bg-yellow-600',
  },
  option2: {
    backgroundColor: 'bg-green-200',
    textColor: 'text-green-700',
    font: 'font-mono',
    cardLightBgColor: 'bg-green-100',
    cardDarkBgColor: 'bg-green-300',
    shadowColor: 'shadow-green-400',
    accentPrimary: 'bg-teal-500',
    accentSecondary: 'bg-pink-600',
  },
  option3: {
    backgroundColor: 'bg-orange-200',
    textColor: 'text-orange-700',
    font: 'font-sans',
    cardLightBgColor: 'bg-orange-100',
    cardDarkBgColor: 'bg-orange-300',
    shadowColor: 'shadow-orange-400',
    accentPrimary: 'bg-red-500',
    accentSecondary: 'bg-purple-600',
  },
};

function App() {
  const [theme, setTheme] = useState(themes.modern);

  return (
    <div className={`min-h-screen ${theme.backgroundColor} ${theme.textColor} ${theme.font}`}>
      <Routes>
        <Route path="/" element={<Home theme={theme} setTheme={setTheme} themes={themes}/>} />
      </Routes>
    </div>
  );
}

export default App;
